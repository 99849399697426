import { LoadingButton } from '@mui/lab';
// import {ButtonChild} from './BaseLayout';
import {defaultColors, ButtonChild, boxShadowStyle} from './Components'
import colors from "@studygenius/shared/Colors";
import { Typography, Link, Button } from "@mui/material";

export const PrimaryLoadingButton = ({children, disabledColor = defaultColors.disabled, hoverColor = defaultColors.hover, backgroundColor = colors.main,onClick, sx, buttonSx, loading, disabled, ...otherProps}) => {
    return (
      <LoadingButton onClick={onClick} loading={loading} disabled={disabled || loading} {...otherProps} 
      sx={{
        backgroundColor: disabled || loading ? disabledColor : backgroundColor,
        minHeight: '44px',
        '&:hover': {
          backgroundColor: disabled  || loading ? disabledColor : hoverColor,
        },
        ...boxShadowStyle,
        ...sx
      }}>
        <ButtonChild sx={{color: loading ? 'transparent' : colors.white, ...buttonSx}} loading={loading}>{children}</ButtonChild>
      </LoadingButton>
    );
};

export const PrimaryWhiteLoadingButton = ({children, onClick, sx, buttonSx, loading, disabled, ...otherProps}) => {
  return (
    <LoadingButton 
    onClick={onClick} loading={loading} disabled={disabled || loading} {...otherProps} 
    sx={{
      backgroundColor: disabled || loading ? colors.lightGray : colors.white,
      height: '42px',

      '&:hover': {
        backgroundColor: disabled  || loading ? defaultColors.disabled : defaultColors.whiteHover,
      },
      ...boxShadowStyle,
      ...sx
    }}>
      <ButtonChild sx={{...buttonSx, color: loading ? 'transparent' : colors.main}} loading={loading}>{children}</ButtonChild>
    </LoadingButton>
  );
};

  