'use client'
import React, {useState, useEffect} from 'react';
import Menu, {POPOVER_CONTENT} from './FullMenu';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import colors from '@studygenius/shared/Colors';
import {Box, Popover, Typography, Button, Dialog, useTheme} from '@mui/material'
import { MUIStyles } from '@studygenius/shared/Variables';
import { CloseButton } from './Components';

export const PopoverWrapper = ({onClose, setPopoverContent}) => <Box sx={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
<CloseButton onClick={() => onClose ? onClose() : setPopoverContent(POPOVER_CONTENT().FALSE)} />

</Box>

const AccountPopovers = ({PopoverContent, children, text='Log in or sign up in seconds', setPopoverContent}) => {
  const theme = useTheme()

  return (<Dialog
    anchorReference="anchorPosition"
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }}
    open={Boolean(PopoverContent)}
    maxWidth="md" // Set maxWidth to 'md' breakpoint
    sx={{
      '& .MuiDialog-paper': {
        maxWidth: '100%', // This ensures we don't have any maxWidth restrictions
        width: '100%', // Set width to be 100% for all viewport widths
        maxHeight: '100%', // Set maxHeight to 100% for all viewport heights
        borderRadius: '6px', // Set the desired borderRadius

        margin: {xs: 1, md: 4},
        // Apply specific styles to medium breakpoint and up
        [theme.breakpoints.up('md')]: { 
          maxWidth: '900px', // Override maxWidth to 600px for 'md' breakpoint and above
          maxHeight: 'calc(100vh - 80px)', // Override maxHeight to "calc(100vh - 80px)"
          borderRadius: '20px' // Override borderRadius to 20px
        },
      },
    }}
    onClose={() => setPopoverContent(POPOVER_CONTENT().FALSE)}
>
  <Box sx={{ backgroundColor: '#fff', width: '100%' }}>

    {PopoverContent}
    {children}
  </Box>
</Dialog>)
}

export default AccountPopovers;