'use client';

import React from 'react';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { useDispatch, useSelector } from '@studygenius/shared/Variables';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Dialog from '@mui/material/Dialog';
import { Hidden } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountPopovers from './AccountPopovers';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';

import colors from '@studygenius/shared/Colors';
import { MUIStyles } from '@studygenius/shared/Variables';

import Login from './login/page';
import Signup from './signup/page';
import ForgotPassword from './popups/ForgotPassword';
import { PrimaryWhiteButton, defaultColors, InvisibleButton, PrimaryButton } from './Components';

export const POPOVER_CONTENT = (props) => ({
  LOGIN: <Login {...props} />,
  SIGNUP: <Signup {...props} />,
  FORGOT_PASSWORD: <ForgotPassword {...props} />,
  FALSE: false,
});

export const menuHeight = '64px';

export const setPopovercontentWithClose = (setPopoverContent) =>
  POPOVER_CONTENT({ close: () => setPopoverContent(false), setPopoverContent });

export default function MenuBar() {
  const router = useRouter();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [PopoverContent, setPopoverContent] = React.useState(false);

  const contentWithClose = setPopovercontentWithClose(setPopoverContent);

  const userEmail = useSelector((state) => state.websocket.userProfile?.email);
  const userName = useSelector((state) => state.websocket.userProfile?.name);
  const userId = useSelector((state) => state.websocket.userProfile?.id);
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const navItems = [
    { text: 'Home', link: '/', icon: CottageOutlinedIcon },
    {
      text: 'About us',
      icon: FormatListBulletedOutlinedIcon,
      subItems: [
        { text: 'Blog', link: '/blog' },
        { text: 'About Us', link: '/about-us' },
      ],
    },
    {
      text: 'Features',
      icon: LibraryBooksOutlinedIcon,
      subItems: [
        { text: 'Youtube summaries and quizzes', link: '/youtube-summarizer-quiz-generator' },
        { text: 'PDF summaries and quizzes', link: '/pdf-summarizer-quiz-generator' },
        { text: 'Webpage summaries and quizzes', link: '/website-summarizer-quiz-generator' },
        { text: 'Manual summaries and quizzes', link: '/manual-text-summarizer-quiz-generator' },
        { text: 'Auto generation and quizzes', link: '/automatic-quiz-generator' },
      ],
    },
    {
      text: 'Your library',
      link: '/user-screen',
      icon: FormatListBulletedOutlinedIcon,
    }
  ];

  // if (userEmail) {
  //   navItems.push({
  //     text: 'Your library',
  //     link: '/user-screen',
  //     icon: FormatListBulletedOutlinedIcon,
  //   });
  // }

  // State to manage which menus are open
  const [menuAnchors, setMenuAnchors] = React.useState({});

  const setUserProfile = (userProfile) => dispatch(SET_USER_PROFILE(userProfile));

  const signOut = async () => {
    await authentication().signOut();
    setUserProfile(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handlePopoverClose = () => {
    setPopoverContent(false);
  };

  const RenderMobileOption = ({ item, index, bottom }) => {
    const Icon = item.icon;
    const [openSubMenu, setOpenSubMenu] = React.useState(false);

    const handleClick = () => {
      if (item.onClick) {
        item.onClick();
        setMobileOpen(false); // Close the drawer
      } else if (item.subItems) {
        // Toggle the open state of submenu
        setOpenSubMenu(!openSubMenu);
      } else if (item.link) {
        router.push(item.link);
        setMobileOpen(false); // Close the drawer
      }
    };

    return (
      <Box>
        <Box
          sx={{
            borderBottom: `1px solid ${colors.text}`,
            padding: 0,
            paddingLeft: 0,
            display: 'flex',
            alignItems: 'center',
            ...(bottom ? { borderBottom: 'none', borderTop: `1px solid ${colors.text}`, padding: 0 } : {}),
          }}
        >
          <PrimaryButton
            aria-haspopup="true"
            onClick={handleClick}
            sx={{
              height: bottom ? '50px' : '70px',
              padding: 2,
              paddingLeft: 1,
              marginLeft: 0,
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            {Icon ? (
              <Icon
                color={colors.white}
                sx={{ color: colors.white, width: '24px', height: '24px', paddingRight: 1 }}
                size={24}
              />
            ) : null}
            <Typography sx={{ ...MUIStyles.nuitoBold, color: colors.white }}>{item.text}</Typography>
            {item.subItems ? (
              openSubMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />
            ) : null}
          </PrimaryButton>
        </Box>
        {item.subItems && openSubMenu && (
          <Box sx={{ pl: 4 }}>
            {item.subItems.map((subItem, subIndex) => (
              <PrimaryButton
                key={subIndex}
                onClick={() => {
                  router.push(subItem.link);
                  setMobileOpen(false); // Close the drawer
                }}
                sx={{
                  paddingLeft: 1,
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography sx={{ ...MUIStyles.nuitoBold, color: colors.white }}>{subItem.text}</Typography>
              </PrimaryButton>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  const mobileOptions = !userEmail
    ? [
        {
          onClick: () => setPopoverContent(contentWithClose.LOGIN),
          text: 'Log in',
          icon: Person2OutlinedIcon,
        },
        {
          onClick: () => setPopoverContent(contentWithClose.SIGNUP),
          text: 'Register',
          icon: PersonAddAlt1OutlinedIcon,
        },
      ]
    : [
        {
          text: 'Account',
          onClick: () => router.push('account'),
          icon: Person2OutlinedIcon,
        },
      ];

  const RightItems = !userEmail ? (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <PrimaryWhiteButton
        variant={'outlined'}
        sx={{ marginLeft: 1, marginRight: 1, height: '42px' }}
        onClick={() => setPopoverContent(contentWithClose.LOGIN)}
      >
        Log in
      </PrimaryWhiteButton>
      <PrimaryButton
        onClick={() => setPopoverContent(contentWithClose.SIGNUP)}
        sx={{ height: '42px' }}
      >
        Register
      </PrimaryButton>
    </Box>
  ) : (
    <InvisibleButton
      isActive={false} // Adjust if you have logic to determine active state
      sx={{ marginLeft: 5 }}
      onClick={() => router.push('account')}
    >
      <Typography
        sx={{ ...MUIStyles.latoBoldParagraph, color: colors.text, marginLeft: 1 }}
      >
        {userName}
      </Typography>
      <Avatar sx={{ backgroundColor: colors.main, width: '32px', height: '32px', marginLeft: 1 }}>
        <PersonIcon sx={{ color: colors.white }} />
      </Avatar>
    </InvisibleButton>
  );

  return (
    <AppBar component="nav" sx={{ height: menuHeight }}>
      <Toolbar
        sx={{
          backgroundColor: colors.white,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => setMobileOpen(true)}
          sx={{ mr: 2, display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon color={colors.main} sx={{ color: colors.main, marginTop: 2 }} />
        </IconButton>
        <Box onClick={() => router.push('/')} sx={{ cursor: 'pointer' }}>
          <img
            src={'/inline-combo-2.png'}
            style={{ width: '254px', height: '48px', marginLeft: '40px' }}
          />
        </Box>
        <Drawer
          sx={{
            height: '0px',
            backgroundColor: colors.main,
            width: '80vw',
            '& .MuiDrawer-paper': {
              backgroundColor: colors.main,
              width: '260px',
            },
          }}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
        >
          {/* Mobile Menu */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box>
              {navItems.map((item, index) => (
                <RenderMobileOption key={index} item={item} index={index} />
              ))}
            </Box>
            <Box>
              {mobileOptions.map((item, index) => (
                <RenderMobileOption key={index} item={item} index={index} bottom={true} />
              ))}
            </Box>
          </Box>
        </Drawer>
        <Hidden smDown>
          {/* Desktop Menu */}
          <Box sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', alignItems: 'center' }}>
            {navItems.map((item, index) => (
              <Box key={index} sx={{ position: 'relative' }}>
                {item.subItems ? (
                  <React.Fragment>
                    <InvisibleButton
                      aria-haspopup="true"
                      onClick={(e) => {
                        setMenuAnchors({ ...menuAnchors, [index]: e.currentTarget });
                      }}
                      isActive={false}
                      childSx={{}}
                    >
                      {item.text}
                    </InvisibleButton>

                    <Menu
                      anchorEl={menuAnchors[index]}
                      open={Boolean(menuAnchors[index])}
                      onClose={() => {
                        setMenuAnchors({ ...menuAnchors, [index]: null });
                      }}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                      {item.subItems.map((subItem, subIndex) => (
                        <MenuItem
                          key={subIndex}
                          onClick={() => {
                            router.push(subItem.link);
                            setMenuAnchors({ ...menuAnchors, [index]: null });
                          }}
                        >
                          {subItem.text}
                        </MenuItem>
                      ))}
                    </Menu>
                  </React.Fragment>
                ) : (
                  <InvisibleButton
                    aria-haspopup="true"
                    onClick={() => router.push(item.link)}
                    isActive={false}
                    childSx={{}}
                  >
                    {item.text}
                  </InvisibleButton>
                )}
              </Box>
            ))}
            {RightItems}
          </Box>
        </Hidden>
      </Toolbar>

      {/* Use AccountPopovers */}
      <AccountPopovers
        PopoverContent={PopoverContent}
        setPopoverContent={setPopoverContent}
      />
    </AppBar>
  );
}