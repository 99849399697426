'use client'

import {Box, TextField, Typography,  Grid, InputAdornment, IconButton} from '@mui/material'
import colors from '@studygenius/shared/Colors'
import { MUIStyles, authentication } from '@studygenius/shared/Variables'
import { PrimaryLoadingButton } from '../ClientComponents'
import {OutlinedGreenButton, BlueTransparentButton,PrimaryLink, defaultColors} from '../Components'
import sigInImage from '../../../public/sign-in.png';
import { getAuth, setPersistence, browserLocalPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import {setPopovercontentWithClose} from '../FullMenu';
import { useState, useEffect } from 'react'
import { saveLearningPlan } from '@studygenius/shared/Requests'
import { useTheme } from '@mui/material/styles';
import { CloseButton } from '../Components'
import { useRouter } from 'next/navigation';


export default function SignUpUi({ isLoading, setIsLoading, value, setValue, signUpPressed, navigation, close, passwordRef, setPopoverContent }) {
  const contentWithClose = setPopovercontentWithClose(setPopoverContent)
  const [part, setPart] = useState(1)
  const theme = useTheme()
  const [userId, setUserId] = useState(false)
  const [isReward, setIsReward] = useState(false);

  const [learningText, setLearningText] = useState('')
    const router = useRouter()
    const openLogin = () => setPopoverContent(contentWithClose.LOGIN)
    const login = async() => {
        const newUserId = await signUpPressed();
        close()

        
    }
    const { pathname } = router;


    const learningPlanClicked = async() => {
      setIsLoading(true)
      const saved = saveLearningPlan(userId, learningText)
      setIsLoading(false)

      close()
    }

    const RenderPart2 = () => {

      return (
        <>
      <Typography sx={{...MUIStyles.paragraph}}>Enter one thing you want to achieve with your learning - this will make it easier for us to make the application useful for you later.</Typography>

                              <TextField
                               
                                onChange={(e) => setLearningText(e.target.value)}
                                value={learningText}
                                multiline
                                variant="standard"
                                label={'Enter your learning goal'}
                                placeholder={'I want to become better at linear algebra'}
                                maxRows={5}
        
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    flex: 1,
                                    marginTop: 3
                                }}
                            />
        <Typography sx={{...MUIStyles.smallParagraph, marginTop: '16px'}}>
          Examples:
          <div>I live in the USA and want to pass Grade 8 Spanish Class</div>
          <div>I live in Sweden and want to pass the theoretical test to obtain my driving licence</div>
          <div>I want to learn about the major psychologists and research that have been made in psychology so I can understand people better in my day to day life</div>
          <div>I want to become better as a UX designer</div>
          <div>I want to know and understand the major characters and stories in the bible</div>
          <div>I want to become more charismatic</div>
      </Typography>

      <PrimaryLoadingButton
                loading={isLoading}
                disabled={!learningText}
                onClick={learningPlanClicked}
                variant={'contained'}
                sx={{ marginTop: '16px' }}>
                Finish Sign up
              </PrimaryLoadingButton>
                        </>
                        )
    }

    // This runs on the client-side only
    useEffect(() => {
      const isReward = window?.location?.href?.includes('create-multigame');
      setIsReward(isReward);
    }, []);

    const title = isReward ? 'Congratulations' : 'Sign up in seconds'
    
    const text = isReward ? 'You have won a free game coin! Sign up to save it and continue the series': 'Create your free account below'
    return (
      <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '16px', marginY: 2, [theme.breakpoints.up('md')]: {marginLeft: '32px'} }}>
      <Typography sx={{...MUIStyles.smallHeader}}>{title}</Typography>
      <CloseButton onClick={() => setPopoverContent(false)} />
    </Box>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2}>
          {/* Form section */}
          <Grid item xs={12} md={7} lg={7} order={{ xs: 2, md: 1 }}>
            <Box sx={{ marginLeft: 2, display: 'flex', flexDirection: 'column', marginRight: 2 }}>
             
              {/* <Box sx={{ width: '100%', backgroundColor: colors.text, height: '1px', marginTop: 1, marginBottom: 0 }} /> */}
              {part === 2 ? <RenderPart2 /> : <>
              <Typography sx={{...MUIStyles.latoBoldParagraph}}>{text}</Typography>

              <TextField variant="standard" label="Name" sx={{ marginTop: 0 }} placeholder="Jane Doe" onChange={(event) => setValue({ ...value, name: event.target.value })} value={value.name} />

  
              <TextField  variant="standard" label="Email" sx={{ marginTop: 2 }} placeholder="Example@gmail.com" onChange={(event) => setValue({ ...value, email: event.target.value })} value={value.email} />
              <TextField
                sx={{ marginTop: 2 }}
                label="Password"
                placeholder="Enter your password"
                type="password"
                name="password"
                variant="standard"
                value={value.password}
                onChange={(event) => setValue({ ...value, password: event.target.value })}
              />
  
              <BlueTransparentButton
                sx={{
                  border: 'none',
                  width: 'fit-content',
                  paddingTop: 1,
                  paddingBottom: 1,
                  marginTop: 5,
                  marginBottom: 1,
                  paddingLeft: 0,

                  textTransform: 'none'
                }}>
                <PrimaryLink
                  sx={{ ...MUIStyles.paragraph, color: defaultColors.link, textTransform: 'none' }}
                  href='https://www.iubenda.com/terms-and-conditions/74869816'>
                  By signing up I accept the terms & conditions
                </PrimaryLink>
              </BlueTransparentButton>
  
              {!!value.error && (
                <Box>
                  <Typography
                    sx={{
                      ...MUIStyles.smallParagraph,
                      color: colors.errorText,
                      marginTop: 0,
                      marginBottom: 2,
                      textAlign: 'center'
                    }}>
                    {value.error}
                  </Typography>
                </Box>
              )}
  
              <PrimaryLoadingButton
                loading={isLoading}
                disabled={!value.email || !value.password || !value.name}
                onClick={login}
                variant={'contained'}
                sx={{ marginTop: 0 }}>
                Sign up
              </PrimaryLoadingButton>
              <OutlinedGreenButton
                onClick={openLogin}
                sx={{ border: 'none', width: 'fit-content', paddingTop: 1, paddingBottom: 1, marginTop: 3, marginBottom: 1, paddingLeft: 0 }}>
                <Typography
                  sx={{ ...MUIStyles.paragraph, color: colors.lightGreen, textTransform: 'none' }}>
                  Already have an account?
                </Typography>
              </OutlinedGreenButton></>}
            </Box>
          </Grid>
  
          {/* Image section */}
          <Grid item xs={12} md={5} lg={5} order={{ xs: 1, md: 2 }} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, alignItems: 'center' }}>
            <img src='/students-5.jpg' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Grid>
        </Grid>
      </Box>
      </>
    );
  }